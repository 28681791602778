import React from "react"
import styled from "styled-components"
import theme from "styled-theming"
import { colors, device } from "../../styles/theme"
import { CreateLocalLink } from "../../utils/createLocalLink"
import Button from "../Button"
import { Container } from "../containers/Container"
import FluidImage from "../FluidImage"
import UniversalLink from "../UniversalLink"
const backgroundColor = theme("mode", {
  light: colors.secondary.default,
  dark: colors.grayscale.light1,
})
const color = theme("mode", {
  light: colors.danger.default,
  dark: colors.accentLight.dark,
})
const StyledCardWrapper = styled(Container)`
  margin: 4rem auto;
  display: grid;
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;

  @media ${device.md} {
    grid-template-columns: repeat(3, 1fr);
  }
`

const ProductCard = styled.div`
  background: ${backgroundColor};
  > div {
    padding: 0 3rem;

    h3,
    h4 {
      margin: 0;
      text-align: center;
    }
    h3 {
      margin: 2rem 0 1rem;
    }

    h4 {
      font-family: var(--font-stack-body);
      font-weight: normal;
      font-style: italic;
      color: ${color};
    }
  }
  > footer {
    display: grid;
    justify-items: center;
    padding: 0 3rem 2rem;
    text-align: center;
  }
`
export default function ProductLine({ wpChildren: { nodes } }) {
  return (
    <StyledCardWrapper as="section">
      {nodes &&
        nodes.length > 0 &&
        nodes.sort((a, b) => parseInt(a.product.cardOrder) - parseInt(b.product.cardOrder)).map(({ featuredImage, product, title, uri, id }) => {
          const {
            productExcerpt,
            productBaseRto,
            rtoAvailable,
            productStartingPrice,
            productNoPrice,
            cardOrder,
            configuratorLink,
          } = product
          return (
            <ProductCard key={id}>
              {featuredImage && (
                <UniversalLink to={configuratorLink}>
                  <FluidImage image={featuredImage.node} />
                </UniversalLink>
              )}
              <div>
                <h3>{title}</h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: `${productExcerpt ? productExcerpt : ""}`,
                  }}
                />
              </div>
              <footer>
                <Button
                  backgroundColor="accentLight"
                  text={title.includes('Gazebo') || title.includes('Teahouse') || title.includes('Playhouse') || title.includes('Microsheds') ? "See local dealer for pricing and options" : (configuratorLink != null ? "Design this Style in 3D" : "3D Shed Design Coming Soon")}
                  uri={configuratorLink}
                  isConfiguratorLink={true}
                />
              </footer>
            </ProductCard>
          )
        })}
    </StyledCardWrapper>
  )
}
