import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

const StyledColorGrid = styled.div`
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));
  grid-auto-flow: dense;
  > p {
    grid-column: 1 /-1;
    text-align: center;
  }
  > div {
    text-align: center;
  }
`

const ColorGrid = ({ colors }) => {
  return (
    <StyledColorGrid>
      <p>
        **Due to differing screen resolutions, colors pictured online may not be
        100% accurate. Please see dealer for exact color samples.
      </p>
      {colors &&
        colors.map(({ colorCode, colorName }) => {
          return (
            <div key={colorCode}>
              <div
                style={{
                  backgroundColor: colorCode,
                  width: `120px`,
                  height: `120px`,
                  borderRadius: `50%`,
                  border: `2px solid white`,
                }}
              />
              <p>{colorName}</p>
            </div>
          )
        })}
    </StyledColorGrid>
  )
}

ColorGrid.propTypes = {
  colors: PropTypes.array.isRequired,
}

export default ColorGrid
