import React from "react"
import styled from "styled-components"
import theme from "styled-theming"
import { colors } from "../styles/theme"

const color = theme("mode", {
  light: colors.danger.default,
  dark: colors.accentLight.default,
})
const StyledProductDisclaimer = styled.div`
  align-self: center;
  justify-self: center;
  text-align: center;
  font-weight: 700;
  font-style: italic;
  max-width: 70rem;
  > p {
    line-height: calc(var(--line-height) * 1.25);
  }
  > :last-child {
    color: ${color};
  }
`
const ProductDisclaimer = (props) => {
  return (
    <StyledProductDisclaimer>
      <p>
        Customer is responsible for all permits and zoning. Delivery man not
        responsible for access, or any yard or property damage on delivery.
      </p>
      <p>
        Sales Tax is added to ALL sales. <br /> Prices subject to change without
        notice.
      </p>
    </StyledProductDisclaimer>
  )
}

export default ProductDisclaimer
