import React from "react"
import styled from "styled-components"
import theme from "styled-theming"
import { Container } from "../../components/containers/Container"
import FluidImage from "../../components/FluidImage"
import { colors } from "../../styles/theme"
const backgroundColor = theme("mode", {
  light: colors.secondary.default,
  dark: colors.grayscale.light1,
})
const StyledContainer = styled(Container)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(40rem, 1fr));
  padding: 4rem 0;

  border-bottom: 1px solid ${colors.accentDark.default};

  > p {
    em {
      color: ${colors.danger.default};
    }
  }
`

const FurnitureGrid = styled(Container)`
  display: grid;
  grid-gap: 2rem;
  padding: 4rem 0;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));

  > div {
    text-align: center;
    background-color: ${backgroundColor};
  }

  h5 {
    padding: 2rem;
    margin: 0;
  }
`
export default function FurnitureTemplate(props)  {
  const { furnitureType } = props
  return (
    <>
      <StyledContainer>
        <p>
          We offer a large selection of premium wooden outdoor furniture that is
          guaranteed to improve your property and provide countless hours of
          rest and relaxation! Check out some of the different models we offer
          below.
          <span>
            <em> Visit local sales lot for pricing.</em>
          </span>
        </p>
        <br></br>
        <p>
        </p>
      </StyledContainer>
      <FurnitureGrid as="section">
        {furnitureType &&
          furnitureType.map(({ furnitureStyle, featuredImage }, index) => (
            <div key={featuredImage.altText || index}>
              <FluidImage image={featuredImage} />
              <h5>{furnitureStyle}</h5>
            </div>
          ))}
      </FurnitureGrid>
    </>
  )
}
