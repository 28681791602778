import React from "react"
import {
  FurnitureTemplate,
  GazeboTemplate,
  PlaysetTemplate,
  ShedTemplate,
} from "."
import { CallToAction } from "../pagebuilder-parts/index"

export default function ProductSingle({
  uri,
  buildingOptions,
  callToAction: { ctaBody, ctaButton },
  productType,
  gazeboType,
  furnitureType,
  productDescription,
  playsetType,
  playsetOptions,
  ...rest
}) {
  const templateMap = {
    Shed: (
      <ShedTemplate
        {...rest}
        productDescription={productDescription}
        buildingOptions={buildingOptions}
      />
    ),
    Gazebo: (
      <GazeboTemplate
        gazeboType={gazeboType}
        uri={uri}
        productDescription={productDescription}
      />
    ),
    Furniture: (
      <FurnitureTemplate
        furnitureType={furnitureType}
        productDescription={productDescription}
      />
    ),
    Playset: (
      <PlaysetTemplate
        playsetType={playsetType}
        options={playsetOptions}
        productDescription={productDescription}
      />
    ),
  }
  return (
    <>
      {templateMap[productType]}
      {ctaButton && ctaButton.buttonLink && (
        <CallToAction ctaBody={ctaBody} ctaButton={ctaButton} />
      )}
    </>
  )
}
