import { graphql } from "gatsby"
import React from "react"
import Layout from "../../components/layout"
import {
  CallToAction,
  PageTitle
} from "../../components/pagebuilder-parts/index"
import Seo from "../../components/Seo"
import ProductLine from "../../components/template-parts/product-line"
import ProductSingle from "../../components/template-parts/product-single"

const Product = ({
  data,
  data: { page, nextPage, previousPage, childOptions },
  location,
}) => {
  const {
    title,
    uri,
    id,
    buildingOptions,
    pageMetadata: { metaKeywords, metaDescription },
    callToAction: { ctaBody, ctaButton },
    product,
    ...rest
  } = page
  const modifiedProduct = {
    ...product,
    title,
    uri,
  }
  return (
    <Layout>
      <Seo title={title} desc={metaDescription} keywords={metaKeywords} />
      <PageTitle title={title} backgroundColor="light" location={location} />
      {page.parentId && page.parentId !== null ? (
        <ProductSingle
          {...modifiedProduct}
          buildingOptions={childOptions.buildingOptions}
          callToAction={childOptions.callToAction}
        />
      ) : (
        <ProductLine {...rest} />
      )}
      {ctaButton && ctaButton.buttonLink && (
        <CallToAction ctaBody={ctaBody} ctaButton={ctaButton} />
      )}
    </Layout>
  )
}

export default Product

export const query = graphql`
  query productTemplate(
    $id: String!
    $nextPage: String
    $previousPage: String
    $parentId: String
  ) {
    page: wpProduct(id: { eq: $id }) {
      id
      parentId
      pageMetadata {
        metaKeywords
        metaDescription
      }
      ...CtaFragment
      ...ProductSingle
      ...ProductLineExcerpt
      wpChildren {
        nodes {
          ...ProductExcerpt
        }
      }
    }

    nextPage: wpProduct(id: { eq: $nextPage }) {
      title
      uri
    }
    previousPage: wpProduct(id: { eq: $previousPage }) {
      title
      uri
    }

    childOptions: wpProduct(id: { eq: $parentId }) {
      ...BuildingOptions
      ...CtaFragment
    }
  }
`
