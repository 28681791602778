import React from "react"
import styled from "styled-components"
import theme from "styled-theming"
import { colors, device } from "../../styles/theme"
import Button from "../Button"
import { Container } from "../containers/Container"
import FluidImage from "../FluidImage"
import ProductDisclaimer from "../ProductDisclaimer"
const color = theme("mode", {
  light: colors.danger.default,
  dark: colors.accentLight.default,
})

const backgroundColor = theme("mode", {
  light: colors.secondary.default,
  dark: colors.grayscale.light1,
})

const Grid = styled(Container)`
  display: grid;
  grid-column-gap: 2rem;
  padding: 4rem 0;
  align-items: center;
  border-bottom: 1px solid ${colors.accentDark.default};
  @media ${device.md} {
    grid-template-columns: 1fr 1fr;
  }
`

const Description = styled.div`
  display: grid;
  grid-gap: 1rem;
  align-items: center;
  justify-items: center;
  text-align: center;
`

const OptionsWrapper = styled.div`
  > h6 {
    grid-column: 1 /-1;
    text-align: center;
    font-size: calc(var(--default-font-size) * 1.125);
    font-style: italic;
    color: ${color};
  }

  > ul {
    display: grid;
    justify-items: center;
    grid-gap: 1rem;
    list-style-type: none;
    padding: 0;
    @media ${device.md} {
      padding-left: 2rem;
      justify-items: flex-start;
      list-style-type: disc;
      grid-template-columns: 1fr 1fr;

      li:nth-child(2n) {
        margin-left: 2rem;
      }
    }
  }
`

const ProductGrid = styled(Container)`
  display: grid;
  grid-gap: 2rem;
  padding: 4rem 0;
  justify-items: center;
  align-items: center;
  @media ${device.md} {
    grid-template-columns: repeat(3, minmax(25rem, 1fr));
  }
  @media ${device.lg} {
    grid-template-columns: repeat(4, minmax(20rem, 1fr));
  }

  article {
    text-align: center;
    background: ${backgroundColor};
    > div {
      padding: 0 3rem 3rem;
    }
    h6 {
      font-size: calc(var(--default-font-size) * 1.125);
      margin: 0;
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
    td:last-of-type {
      text-align: right;
    }

    tr:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }

  > div {
    grid-column: 1/-1;
  }
`
export default function GazeboTemplate({ gazeboType, uri, productDescription })  {
  return (
    <>
      <section>
        <Grid>
          <Description>
            <p
              dangerouslySetInnerHTML={{
                __html: `${productDescription}`,
              }}
            />
            <Button
              uri="/learn-more"
              text="Request A Call"
              icon="phone"
              backgroundColor="danger"
            />
          </Description>
          <OptionsWrapper>
            {uri && !uri.includes("value-gazebo") && (
              <>
                <h6>
                  Many options available. See local salesperson for details.
                </h6>
                <ul>
                  <li>Double Roof</li>
                  <li>Screens</li>
                  <li>Screened Floor</li>
                  <li>Colonial Rails</li>
                  <li>Electrical Package</li>
                  <li>Cedar Shake Roof</li>
                  <li>Cupola</li>
                  <li>Benches</li>
                  <li>Swings</li>
                  <li>Sealer or Paint</li>
                  <li>Metal or Asphalt Shingle Roof</li>
                  <li>Octagon or Rectangle Tables</li>
                </ul>
              </>
            )}
            {uri && uri.includes("value-gazebo") && (
              <h6>
                Limited options available on Value Models. See local salesperson
                for details and options.
              </h6>
            )}
          </OptionsWrapper>
        </Grid>
      </section>
      <section>
        <ProductGrid>
          {gazeboType &&
            gazeboType.map(({ gazeboPrices, gazeboStyle, featuredImage }) => (
              <article key={gazeboStyle}>
                {featuredImage && <FluidImage image={featuredImage} />}
                <div>
                  <h2>{gazeboStyle}</h2>
                  <h6>Base Pricing</h6>
                  <table>
                    <tbody>
                      {gazeboPrices &&
                        gazeboPrices.map(({ gazeboPrice, gazeboSize }) => (
                          <tr key={gazeboSize}>
                            <td>{gazeboSize}</td>
                            <td>{`$${gazeboPrice.toFixed(2)}`}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </article>
            ))}
          <ProductDisclaimer />
        </ProductGrid>
      </section>
    </>
  )
}
