import React from "react"
import styled from "styled-components"
import theme from "styled-theming"
import { Container } from "../../components/containers/Container"
import FluidImage from "../../components/FluidImage"
import { colors, device } from "../../styles/theme"

const backgroundColor = theme("mode", {
  light: colors.secondary.default,
  dark: colors.grayscale.light1,
})
const StyledContainer = styled(Container)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(40rem, 1fr));
  padding: 4rem 0;
  &:first-of-type {
    border-bottom: 1px solid ${colors.accentDark.default};
  }

  gap: 0 2rem;

  &.attachments {
    @media ${device.md} {
      grid-template-columns: 1fr 1fr;
    }
    place-items: top centerj;
    h3 {
      margin-top: 0;
    }
    h2 {
      grid-column: 1/-1;
    }
    > ul {
      h4 {
        margin: 0;
      }
      p {
        margin-top: 0;
      }
    }

    .other-options {
      &__list {
        line-height: calc(var(--line-height) * 1.125);
      }
    }
  }
`

const PlaysetGrid = styled(Container)`
  display: grid;
  grid-gap: 2rem;
  padding: 4rem 0;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));

  h6,
  h5 {
    margin: 0;
  }
  h5 {
    text-align: center;
    font-weight: 600;
    margin-bottom: 2rem;
  }
`

const Card = styled.div`
  background-color: ${backgroundColor};

  > div {
    padding: 2rem;

    p {
      margin-top: 0.5rem;
    }
  }
`
export default function PlaysetTemplate({ playsetType, options }) {
  return (
    <>
      <StyledContainer as="section">
        <p>
          We offer a large selection of premium playset styles that are
          guaranteed to improve your property as well as provide countless hours
          of family fun! Check out the pictures below of past setups to get
          ideas for your own custom playset!{" "}
          <span>
            <em>Visit local sales lot for pricing.</em>
          </span>
        </p>
        <p>

        </p>
      </StyledContainer>
      <PlaysetGrid as="section">
        {playsetType &&
          playsetType.map(
            (
              {
                playsetStyle,
                playsetDescription,
                additionalOptions,
                featuredImage,
              },
              index
            ) => (
              <Card key={`featuredImage.altText${index}`}>
                <FluidImage image={featuredImage} />
                <div>
                  <h5>{playsetStyle}</h5>
                  <h6>Description</h6>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: `${playsetDescription}`,
                    }}
                  />
                  <h6>Additional Options:</h6>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: `${additionalOptions}`,
                    }}
                  />
                </div>
              </Card>
            )
          )}
      </PlaysetGrid>
      <StyledContainer as="section" className="attachments">
        <h2>Playset Attachments</h2>
        <ul>
          {options &&
            options.playsetAttachments.map(({ name, description }) => {
              return (
                <li key={name}>
                  <h4>{name}</h4>
                  <p>{description}</p>
                </li>
              )
            })}
        </ul>
        {options && options.otherOptions && (
          <div className="other-options">
            <h3>Other Options</h3>
            <ul className="other-options__list">
              {options.otherOptions.map(({ name }) => {
                return <li key={name}>{name}</li>
              })}
            </ul>
          </div>
        )}
      </StyledContainer>
    </>
  )
}
