import { graphql, useStaticQuery } from "gatsby"
import _isEmpty from "lodash.isempty"
import React, { useState } from "react"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import styled from "styled-components"
import theme from "styled-theming"
import { colors, device } from "../../styles/theme"
import Button from "../Button"
import ColorGrid from "../ColorGrid"
import { Container } from "../containers/Container"
import CustomSelect from "../CustomSelect"
import ImageCarousel from "../ImageCarousel"
import ProductDisclaimer from "../ProductDisclaimer"
const themedRTOColor = theme("mode", {
  light: colors.primary.default,
  dark: colors.primary.lighter,
})

const StyledContainer = styled(Container)`
  display: grid;
  grid-gap: 2rem;
  @media ${device.md} {
    grid-template-columns: 1fr 1fr;
  }

  > div {
    margin: 2rem 0;
  }

  .standard-features {
    text-align: center;
    > ul {
      list-style-type: none;
      list-style-position: outside;
      padding: 0;
      margin: 0;

      @media ${device.md} {
        padding-left: 2rem;
        list-style-type: disc;
        box-sizing: border-box;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
        grid-column-gap: 2rem;
        grid-row-gap: 1rem;
        text-align: left;
      }
      @media ${device.xl} {
        grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
      }
      @media ${device.xxl} {
        grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
      }
    }
    li:not(:last-of-type) {
      margin-bottom: 2rem;
      @media ${device.md} {
        margin-bottom: 0;
      }
    }
  }
`

const Description = styled.article`
  display: grid;
  align-items: center;
  justify-items: center;
  align-self: flex-start;
  margin-top: 2rem;
  > h2 {
    margin: 0;
    justify-self: left;
  }
  h4 {
    margin: 0;
    margin-top: 2rem;
    font: italic 500 calc(var(--default-font-size) * 1.25)
      var(--font-stack-body);
    span {
      font-size: calc(var(--default-font-size) * 1.25);
    }
    &:last-of-type,
    &:last-of-type span {
      color: ${themedRTOColor};
    }
  }

  > a {
    margin: 2rem auto;
  }
`
const StyledTabs = styled(Tabs)`
  grid-column: 1 /-1;
`
const StyledTabList = styled(TabList)`
  list-style: none;
  display: flex;
  justify-content: space-between;
  padding: 0;
  justify-items: center;
  border-bottom: 1px solid ${colors.accentDark.default};
  position: relative;
  @media ${device.sm} {
    justify-content: flex-start;
  }
  li {
    padding: 1rem;
    cursor: pointer;
  }

  .react-tabs {
    border: none;
  }

  .react-tabs__tab--selected {
    font-weight: 700;
    width: max-content;
    position: relative;
    color: ${themedRTOColor};
  }
  .react-tabs__tab--selected::after {
    position: absolute;
    content: "";
    display: block;
    height: 3px;
    width: 100%;
    bottom: -4px;
    left: 0;
    border-bottom: 4px solid ${colors.accentLight.default};
  }
`
const BuildingOptionsGrid = styled.section`
  margin: 4rem 1rem;
  column-gap: 3rem;
  column-count: 1;

  @media ${device.md} {
    column-count: 2;
  }
  @media ${device.lg} {
    column-count: 3;
  }
  > div {
    break-inside: avoid;
    &:first-of-type h4 {
      margin-top: 0;
    }

    h4 {
      margin-bottom: 1rem;
      text-align: center;
    }

    table {
      width: 100%;
      border-collapse: collapse;
      line-height: calc(var(--line-height) * 1.5);

      tr {
        transition: transform 200ms ease;
        padding: 0 1rem;
        &:hover {
          color: ${colors.primary.default};
          background: ${colors.secondary.default};
          transform: translateX(1rem);
        }
      }
      td {
        text-align: left;
        padding-left: 1rem;
      }
      td:last-child {
        padding-left: 2rem;
        text-align: right;
        padding-right: 1rem;
      }

      .option__description {
        font-size: 1.4rem;
        text-align: center !important;
        font-weight: 600;
        line-height: 1.4;
        pointer-events: none;
      }
    }
  }
`
export default function ShedTemplate(props) {
  const {
    title,
    productDescription,
    productSizes,
    productGallery,
    addGallery,
    productStandardFeatures,
    productOptions,
    buildingOptions,
  } = props
  const [productPrice, setproductPrice] = useState("")

  const {
    wp: {
      productAddons: {
        colors: { colors: metalColors },
      },
    },
  } = useStaticQuery(graphql`
    {
      wp {
        productAddons {
          colors {
            colors {
              colorName
              colorCode
            }
          }
        }
      }
    }
  `)
  const availableProductOptions = productOptions.reduce((acc, currVal) => {
    const title = currVal
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase())
    acc.push({ optionTitle: title, options: buildingOptions[currVal] })
    return acc
  }, [])

  // const metalColors = []
  const onProductSizeChange = (e) => {
    setproductPrice(e.target.value)
  }
  return (
    <>
      <StyledContainer>
        {addGallery === "Yes" && (
          <div>{<ImageCarousel imageGallery={productGallery} />}</div>
        )}
        <Description>
          <h2 dangerouslySetInnerHTML={{ __html: `${title}` }} />
          <p dangerouslySetInnerHTML={{ __html: `${productDescription}` }} />
          <p>
            <strong>Free Delivery</strong> is included within 50 miles of the
            sales lot as well as normal setup.
          </p>
          <CustomSelect
            handleChange={onProductSizeChange}
            label="Product Sizes"
            name="shedSizes"
            id="shedSizes"
            defaultValue={productPrice}
          >
            <option value="" disabled>
              Select A Size
            </option>
            {productSizes &&
              productSizes.map(({ price, size }) => (
                <option key={size} value={price} label={size}>
                  {size}
                </option>
              ))}
          </CustomSelect>
          {productPrice && (
            <>
              <h4>
                Base Price:{" "}
                <span
                  dangerouslySetInnerHTML={{
                    __html: `$${productPrice
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} + tax`,
                  }}
                />
              </h4>
              <h4>
                36 Mo. RTO:{" "}
                <span
                  dangerouslySetInnerHTML={{
                    __html: `${(productPrice / 21.6).toFixed(2)} + tax`,
                  }}
                />
              </h4>
            </>
          )}
          <Button
            backgroundColor="danger"
            uri="/learn-more"
            text="Request A Call"
            icon="phone"
          />
        </Description>
      </StyledContainer>
      <StyledContainer>
        <div className="standard-features">
          <h3>Standard Features</h3>
          <ul>
            {productStandardFeatures &&
              productStandardFeatures
                .split(/\r?\n|,/)
                .map((item) => <li key={item}>{item}</li>)}
          </ul>
        </div>
        <ProductDisclaimer />
      </StyledContainer>
      <StyledContainer>
        <StyledTabs>
          <StyledTabList>
            <Tab>Building Options</Tab>
            {buildingOptions.sidingColors &&
              !_isEmpty(buildingOptions.sidingColors) && (
                <Tab>Siding Color</Tab>
              )}
            {metalColors && !_isEmpty(metalColors) && <Tab>Roof Color</Tab>}
          </StyledTabList>

          <TabPanel>
            <BuildingOptionsGrid>
              {availableProductOptions &&
                availableProductOptions.map(({ optionTitle, options = [] }) => {
                  return (
                    <div key={optionTitle}>
                      <h4>{optionTitle}</h4>
                      <table>
                        <tbody>
                          {options.map(
                            ({
                              optionName,
                              optionPrice,
                              optionDescription,
                            }) => (
                              <React.Fragment key={optionName}>
                                <tr key={optionName}>
                                  <td
                                    colSpan={optionPrice ? 1 : 2}
                                    style={{
                                      textAlign: !optionPrice && `center`,
                                      fontSize: !optionPrice && `1.4rem`,
                                    }}
                                  >
                                    {optionName}
                                  </td>
                                  {optionPrice && <td>{optionPrice}</td>}
                                </tr>
                                {optionDescription && (
                                  <tr>
                                    <td
                                      className="option__description"
                                      colSpan="2"
                                    >
                                      {optionDescription}
                                    </td>
                                  </tr>
                                )}
                              </React.Fragment>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  )
                })}
            </BuildingOptionsGrid>
          </TabPanel>
          {buildingOptions.sidingColors &&
            !_isEmpty(buildingOptions.sidingColors) && (
              <TabPanel>
                <section>
                  <h2>Siding Colors (*Standard Colors)</h2>
                  <ColorGrid colors={buildingOptions.sidingColors} />
                  <small
                    style={{
                      display: `block`,
                      textAlign: `center`,
                      margin: `3rem 0`,
                    }}
                  >
                    *Ask about custom colors.
                  </small>
                </section>
              </TabPanel>
            )}
          {metalColors && !_isEmpty(metalColors) && (
            <TabPanel>
              <section>
                <h2>#1 40 Year Roofing Metal</h2>
                <ColorGrid colors={metalColors} />
              </section>
            </TabPanel>
          )}
        </StyledTabs>
      </StyledContainer>
    </>
  )
}
